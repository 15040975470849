(() => {
  const BWDGallery = () => {
    const selectors = {
      component: "bwd-gallery",
    };

    const init = () => {
      const parentElement = Array.from(document.getElementsByClassName(selectors.component));
      if (parentElement) {
        parentElement.forEach((element) => {
          const swiperElement = element?.querySelector(".swiper");
          if (swiperElement) {
            new window.Swiper(element.querySelector(".swiper"), {
              slidesPerView: window.innerWidth > 1024 ? 4.1 : 1.75,
              spaceBetween: 24,
              freeMode: false,
              loop: false,
              centeredSlides: false,
            });
          }

          window.addEventListener("resize", () => {
            if (swiperElement) {
              new window.Swiper(element.querySelector(".swiper"), {
                slidesPerView: window.innerWidth > 1024 ? 4.1 : 1.75,
                freeMode: false,
                loop: false,
                spaceBetween: 24,
                centeredSlides: false,
              });
            }
          });
        });
      }
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDGallery);
})();
