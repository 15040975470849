(() => {
  const BWDWeddingCalendar = () => {
    const selectors = {
      component: "bwd-weddings-calendar",
    };

    const forceArrowVisibility = (swiper, leftArrow, rightArrow) => {
      leftArrow?.classList?.toggle("swiper-button-disabled", swiper.isBeginning);
      rightArrow?.classList?.toggle("swiper-button-disabled", swiper.isEnd);
    };

    const changeGradient = (swiper, leftArrow, rightArrow) => {
      if (!leftArrow || !rightArrow) {
        return;
      }
      const mutObs = new MutationObserver((mutationList) => {
        mutationList.filter((mutation) => mutation.attributeName === "class").forEach(() => forceArrowVisibility(swiper, leftArrow, rightArrow));
      });
      mutObs.observe(leftArrow, { attributes: true });
      mutObs.observe(rightArrow, { attributes: true });
    };

    const init = () => {
      Array.from(document.getElementsByClassName(selectors.component)).forEach((element) => {
        const swiperElement = element.querySelector(".swiper");
        const swiperWrapper = swiperElement.querySelector(".swiper-wrapper");
        const leftArrow = swiperElement.querySelector(".swiper-button-prev");
        const rightArrow = swiperElement.querySelector(".swiper-button-next");
        if (swiperElement && swiperWrapper.children.length > 1) {
          new window.Swiper(element.querySelector(".swiper"), {
            slidesPerView: 2.5,
            spaceBetween: 100,
            breakpoints: {
              1024: {
                slidesPerView: 3,
                spaceBetween: 250,
              },
            },
            freeMode: false,
            loop: false,
            centeredSlides: true,
            navigation: {
              nextEl: rightArrow,
              prevEl: leftArrow,
            },
            on: {
              afterInit: (swiper) => {
                const div = document.createElement("div");
                div.classList.add("bwd-weddings-calendar__carousel-mask");
                swiper.wrapperEl.parentNode.insertBefore(div, swiper.wrapperEl);
                div.appendChild(swiper.wrapperEl);

                changeGradient(swiper, leftArrow, rightArrow);
                forceArrowVisibility(swiper, leftArrow, rightArrow);
              },
              reachBeginning: (swiper) => forceArrowVisibility(swiper, leftArrow, rightArrow),
              reachEnd: (swiper) => forceArrowVisibility(swiper, leftArrow, rightArrow),
            },
          });
        } else {
          const navigations = element.querySelector(".js-search-navigation");
          navigations.style.display = "none";
        }
      });
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDWeddingCalendar);
})();
