import * as bwdContactForm from "../../content/bwd-contact-form/bwd-contact-form";

(() => {
  const BWDPackageDetail = () => {
    const selectors = {
      component: ".bwd-package-detail",
      destinations: ".bwd-menu__links-link",
      cards: ".bwd-comparator-card",
      swiper: ".js-init-swiper",
      modalButton: ".js-open-modal",
      modalBlock: ".js-contact-modal",
    };

    const initBackButton = (component) => {
      Array.from(component.getElementsByClassName("bwd-package-detail__back")).forEach((btnBack) => {
        if (history.length < 2) {
          btnBack.classList.add("hidden");
          return;
        }
        btnBack.addEventListener("click", (evt) => {
          evt.preventDefault();
          history.back();
        });
      });
    };

    const initContactFormButton = (btn) => {
      let alreadyAttachedToModal = false;
      bwdContactForm.addEventListenerOnInserted((contactFormElem) => {
        if (alreadyAttachedToModal) {
          return;
        }
        alreadyAttachedToModal = true;

        const modal = contactFormElem.querySelector(selectors.modalBlock);
        btn.classList.remove("hidden");
        btn.addEventListener("click", () => {
          if (modal) {
            modal.classList.add("mod--open");
            document.documentElement.classList.add("mod--disable-scroll");
          }
        });
      });
    };

    const resetDestinations = (destinations) => {
      destinations.forEach((destination) => {
        destination.classList.remove("mod--selected");
      });
    };

    const resetHotelCards = (hotelCards) => {
      hotelCards.forEach((hotelCard) => {
        hotelCard.classList.remove("hidden");
      });
    };

    const setHotelsByDestination = (destination, hotelCards) => {
      destination.classList.add("mod--selected");
      const destinationId = destination.dataset?.destinationId;
      if (destinationId !== "All") {
        hotelCards.forEach((hotelCard) => {
          if (destinationId !== hotelCard.dataset?.destinationId) {
            hotelCard.classList.add("hidden");
          }
        });
      }
    };

    const filterHotelCardsByDestination = (elem) => {
      const destinations = elem.querySelectorAll(selectors.destinations);
      const hotelCards = elem.querySelectorAll(selectors.cards);
      destinations.forEach((destination) => {
        destination.addEventListener("click", () => {
          resetDestinations(destinations);
          resetHotelCards(hotelCards);
          setHotelsByDestination(destination, hotelCards);
        });
      });
    };

    const init = () => {
      const parentElement = document.querySelectorAll(selectors.component);
      if (!parentElement || parentElement.length === 0) {
        return;
      }

      const body = document.querySelector("body");
      const isParentPackageDetail = Array.from(parentElement).every((element) => element.classList.contains("bwd-package-detail"));
      if (parentElement.length > 0 && isParentPackageDetail) {
        setTimeout(function () {
          if (body.dataset.currency !== "USD") {
            const rate = bcl.s.currency.props.marketRelativeRatesMap.USD.rate;
            const inverseRate = bcl.s.currency.props.marketRelativeRatesMap.USD.inverse;
            bcl.s.currency.changeCurrency("USD", rate, null, true);
            const spans = body.querySelectorAll(".c-price__value-JS");
            spans.forEach((span) => {
              const price = span.dataset?.marketPrice;
              span.dataset.marketPrice = Math.ceil(price * inverseRate);
              span.dataset.relativeprice = price;
              span.innerText = bcl.s.currency.formatPrice(price);
            });
          }
        }, 500);
      }
      parentElement.forEach((element) => {
        initBackButton(element);
        element?.querySelectorAll(selectors.modalButton)?.forEach((btn) => initContactFormButton(btn));
        filterHotelCardsByDestination(element);

        const swiperElement = element.querySelector(selectors.swiper);
        if (!swiperElement || swiperElement.getElementsByClassName("swiper-slide").length === 0) {
          return;
        }
        try {
          new window.Swiper(swiperElement, {
            slidesPerView: 1,
            freeMode: false,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            loop: false,
            nested: element.classList.contains("swiper-nested"),
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        } catch (err) {
          console.error(`Error initializing Swiper in ${selectors.component.substring(1)}`, err);
        }
      });
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDPackageDetail);
})();
