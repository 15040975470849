import { Cards } from "./cards";
import { iconPoint, iconSelected } from "./icons";
import { addChangeTouchDeviceListener } from "./input-device";
import { loadGMaps, newMarker } from "./google-maps";
import { labelWithStyles, gMapStyles } from "./styles";
import { Modal } from "./modal";

/**
 * Represents the component Map for the Barceló Wedding project.
 * @class WeddingMap
 * @classdesc This component contains 3 selectors that determines the current view: Continent, Country, and Hotel.
 * This component expects to have them selected or de-selected in order, and will throw errors if they are not.
 */
export class WeddingMap {
  /**
   * Constructor for WeddingMap component. Its returned value is not intended to be used.
   *
   * If initialization is successful, the component will be fully initialized and attached to the DOM element of the
   * component, as "weddingMap".
   * @param {HTMLElement} elemRoot Root element of the component to initialize.
   */
  constructor(elemRoot) {
    /** @type {Cards|null} */
    this.cards = null;

    /** @type {google.maps.Map|null} */
    this.map = null;

    /** @type {GlobalMarkers} */
    this.markers = new Map();

    /** @type {Modal|null} */
    this.modal = null;

    /** @type {{continent: string, country: string, hotel: string}} */
    this.selection = {
      continent: "",
      country: "",
      hotel: "",
    };

    /** @type {HTMLElement} */
    this.zoomControl = null;

    /** @type {{continent: number, country: number, hotel: number}} */
    this.zoomLevels = {
      continent: 3,
      country: 4,
      hotel: 6,
    };

    /** @type {number|null} */
    this.latamLatitude = null;

    /** @type {number|null} */
    this.latamLongitude = null;

    /** @type {number|null} */
    this.europeLatitude = null;

    /** @type {number|null} */
    this.europeLongitude = null;

    /** @type {number|null} */
    this.asiaLatitude = null;

    /** @type {number|null} */
    this.asiaLongitude = null;

    /** @type {number|null} */
    this.africaLatitude = null;

    /** @type {number|null} */
    this.africaLongitude = null;

    this.init(elemRoot);
  }

  /**
   * Initializes the asynchronous part of the component.
   * @param {HTMLElement} elemRoot Root element of the component to initialize.
   * @returns {Promise<void>} Promise that resolves when initialation is complete.
   */
  async init(elemRoot) {
    const elemContainer = elemRoot.getElementsByClassName("bwd-map__container")[0];
    if (!elemContainer) {
      return;
    }

    // Doing promises in parallel for better performance
    const data = this.fetch(elemRoot.getElementsByClassName("bwd-map__wrapper")[0]?.dataset?.path);
    await loadGMaps();
    this.initializeCoordinates(elemRoot.getElementsByClassName("bwd-map__wrapper")[0]);
    this.createMarkers(await data);

    // Init map and markers
    const mapElement = elemContainer.getElementsByClassName("bwd-map__element")[0];
    this.map = new window.google.maps.Map(mapElement, {
      center: new window.google.maps.LatLng(0, 0),
      fullscreenControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      streetViewControl: false,
      styles: gMapStyles,
      zoom: 2,
      zoomControl: false,
      zoomControlOptions: { position: window.google.maps.ControlPosition.INLINE_START_BLOCK_START },
    });
    this.showMarkers(this.markers, true, this.zoomLevels.continent);
    addChangeTouchDeviceListener((isTouchDevice) => this.showLabels(isTouchDevice));

    // Init map controls
    const zoomControlWrapper = elemContainer.getElementsByClassName("bwd-map__zoom-controls")[0];
    this.zoomControl = zoomControlWrapper.getElementsByTagName("button")[0];
    zoomControlWrapper.classList.remove("hidden");
    zoomControlWrapper.addEventListener("click", () => (this.zoomControl.disabled = !this.zoomOut()));
    mapElement.appendChild(zoomControlWrapper);

    // Init cards
    this.cards = new Cards(elemContainer, mapElement);
    this.modal = new Modal(elemContainer, () => this.setSelectedHotel(""));

    // Attach object in DOM to open it for future modification
    elemRoot.weddingMap = this;
  }

  /**
   * Fetches the info of the hotels.
   * @param {string} url - URL to fetch the hotel info from.
   * @returns {Promise<HotelInfo[]>} Hotel data returned by the server.
   */
  async fetch(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.error("bwd-map: server responded with error response:", response);
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error("bwd-map: error loading data:", error);
      return null;
    }
  }

  /**
   * Process the server data and populates the "markers" attributes of this object.
   * @param {HotelInfo[]} data Data returned by the server.
   */
  createMarkers(data) {
    data.forEach((hotelInfo) => {
      if (!this.markers.has(hotelInfo.continent)) {
        if (this.overrideCords(hotelInfo.continentId) != null) {
          hotelInfo.continentCord = this.overrideCords(hotelInfo.continentId);
        }
        const marker = newMarker(hotelInfo.continent, hotelInfo.continentCord);
        marker.addListener("click", () => this.setSelectedContinent(hotelInfo.continent));
        this.markers.set(hotelInfo.continent, { countries: new Map(), marker });
      }

      const countries = this.markers.get(hotelInfo.continent).countries;
      if (!countries.has(hotelInfo.country)) {
        const marker = newMarker(hotelInfo.country, hotelInfo.countryCord);
        marker.addListener("click", () => this.setSelectedCountry(hotelInfo.country));
        countries.set(hotelInfo.country, { hotels: new Map(), marker });
      }

      const marker = newMarker(hotelInfo.hotelName, hotelInfo.hotelCord);
      marker.addListener("click", () => this.setSelectedHotel(hotelInfo.hotelName));
      countries.get(hotelInfo.country).hotels.set(hotelInfo.hotelName, {
        marker,
        path: hotelInfo.hotelPath,
      });
    });
  }

  /**
   * Shows or hides the label of the markers.
   * @param {boolean} show Whether to show or hide the labels.
   */
  showLabels(show) {
    this.markers.forEach((continent, continentName) => {
      continent.marker.setLabel(show ? labelWithStyles(continentName) : null);
      continent.countries.forEach((country, countryName) => {
        country.marker.setLabel(show ? labelWithStyles(countryName) : null);
        country.hotels.forEach((hotel, hotelName) => {
          hotel.marker.setLabel(show ? labelWithStyles(hotelName) : null);
        });
      });
    });
  }

  /**
   * Shows or hides the markers provided.
   * @param {ContinentMarker|CountryMarker|GlobalMarkers} placesMap Map of markers to show or hide. If it's falsy, it hides them.
   * @param {boolean} show Whether to show or hide the markers.
   * @param {number|undefined} singlePointZoom Default zoom level to use if only one (1) marker is found. If not
   * provided, the zoom level doesn't change.
   */
  showMarkers(placesMap, show, singlePointZoom) {
    const map = show ? this.map : null;

    if (placesMap.size === 1) {
      const marker = Array.from(placesMap.values())[0].marker;
      marker.setMap(map);
      if (show) {
        map.setCenter(marker.getPosition());
        map.setZoom(singlePointZoom);
      }
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    placesMap.forEach((place) => {
      place.marker.setMap(map);
      bounds.extend(place.marker.getPosition());
    });
    if (show) {
      map.fitBounds(bounds);
    }
  }

  /**
   * Event triggered when navigating from global to continent view.
   * @param {string} continentName Name of the continent navigating towards.
   */
  navInToContinent(continentName) {
    const continents = this.markers;
    this.zoomControl.disabled = false;
    this.showMarkers(continents, false);
    this.showMarkers(continents.get(continentName).countries, true, this.zoomLevels.country);
  }

  /**
   * Event triggered when navigating from continent to country view.
   * @param {string} countryName Name of the country navigating towards.
   */
  navInToCountry(countryName) {
    const countries = this.markers.get(this.selection.continent).countries;
    const hotels = countries.get(countryName).hotels;
    this.cards.showCarousel(true);
    this.cards.loadCountryCards(hotels, countryName);
    this.showMarkers(countries, false);
    this.showMarkers(hotels, true, this.zoomLevels.hotel);
  }

  /**
   * Event triggered when navigating from country to hotel view.
   * @param {string} hotelName Name of the hotel navigating towards.
   */
  navInToHotel(hotelName) {
    const hotel = this.markers.get(this.selection.continent).countries.get(this.selection.country).hotels.get(hotelName);
    this.cards.showCarousel(false);
    this.modal.showModal(hotel.html);
    hotel.marker.setIcon(iconSelected());
  }

  /**
   * Event triggered when navigating from continent to global view.
   */
  navOutFromContinent() {
    const continents = this.markers;
    this.showMarkers(continents.get(this.selection.continent).countries, false);
    this.showMarkers(continents, true, this.zoomLevels.continent);
  }

  /**
   * Event triggered when navigating from country to continent view.
   */
  navOutFromCountry() {
    const countries = this.markers.get(this.selection.continent).countries;
    this.cards.showCarousel(false);
    this.showMarkers(countries.get(this.selection.country).hotels, false);
    this.showMarkers(countries, true, this.zoomLevels.country);
  }

  /**
   * Event triggered when navigating from hotel to country view.
   */
  navOutFromHotel() {
    const hotel = this.markers.get(this.selection.continent).countries.get(this.selection.country).hotels.get(this.selection.hotel);
    this.cards.showCarousel(true);
    this.modal.showModal(null);
    hotel.marker.setIcon(iconPoint());
  }

  /**
   * Sets the selected continent and adjust the pan and zoom automatically.
   *
   * You MUST have not selected country or hotel. An exception will be thrown if they are defined.
   * @param {string} selectedContinent Continent name to select, or "" to deselect.
   */
  setSelectedContinent(selectedContinent) {
    if (this.selection.country !== "" || this.selection.hotel !== "") {
      throw new Error("bwd-map: selection out of order: trying to modify continent with country or hotel already defined");
    }
    if (selectedContinent === "") {
      this.navOutFromContinent();
    } else {
      this.navInToContinent(selectedContinent);
    }
    this.selection.continent = selectedContinent;
  }

  /**
   * Sets the selected country and adjust the pan and zoom automatically.
   *
   * You MUST have a continent selected, and a hotel not selected. An exception will be thrown otherwise.
   * @param {string} selectedCountry Country name to select, or "" to deselect.
   */
  setSelectedCountry(selectedCountry) {
    if (this.selection.continent === "" || this.selection.hotel !== "") {
      throw new Error("bwd-map: selection out of order: trying to modify country with continent undefined or hotel defined");
    }
    if (selectedCountry === "") {
      this.navOutFromCountry();
    } else {
      this.navInToCountry(selectedCountry);
    }
    this.selection.country = selectedCountry;
  }

  /**
   * Sets the selected hotel and adjust the pan and zoom automatically.
   *
   * You MUST have selected country AND hotel. An exception will be thrown otherwise.
   * @param {string} selectedHotel Hotel name to select, or "" to deselect.
   */
  setSelectedHotel(selectedHotel) {
    if (this.selection.continent === "" || this.selection.country === "") {
      throw new Error("bwd-map: selection out of order: trying to modify hotel with continent or country undefined");
    }
    if (selectedHotel === "") {
      this.navOutFromHotel();
    } else if (this.selection.hotel === "") {
      this.navInToHotel(selectedHotel);
    } else {
      const hotels = this.markers.get(this.selection.continent).countries.get(this.selection.country).hotels;
      const previous = hotels.get(this.selection.hotel);
      const selected = hotels.get(selectedHotel);

      previous.marker.setIcon(iconPoint());
      selected.marker.setIcon(iconSelected());
      this.modal.showModal(selected.html);
    }
    this.selection.hotel = selectedHotel;
  }

  /**
   * Reduces the level of zoom by deselecting the most specific item.
   * @returns {boolean} True if it can be zoomed out further. False otherwise.
   */
  zoomOut() {
    if (this.selection.hotel !== "") {
      this.setSelectedHotel("");
      return true;
    }
    if (this.selection.country !== "") {
      this.setSelectedCountry("");
      return true;
    }
    if (this.selection.continent !== "") {
      this.setSelectedContinent("");
      return false;
    }
    return false;
  }

  /**
   * Fill the variables with the data attrabutes of the DIV
   * @param {*} elementDiv
   */
  initializeCoordinates(elementDiv) {
    this.latamLatitude = parseFloat(elementDiv?.dataset?.latamLtd);
    this.latamLongitude = parseFloat(elementDiv?.dataset?.latamLng);
    this.europeLatitude = parseFloat(elementDiv?.dataset?.europeLtd);
    this.europeLongitude = parseFloat(elementDiv?.dataset?.europeLng);
    this.asiaLatitude = parseFloat(elementDiv?.dataset?.asiaLtd);
    this.asiaLongitude = parseFloat(elementDiv?.dataset?.asiaLng);
    this.africaLatitude = parseFloat(elementDiv?.dataset?.africaLtd);
    this.africaLongitude = parseFloat(elementDiv?.dataset?.africaLng);
  }

  /**
   * Overrides the continent's coordinates if the fields are filled out.
   * @param {string} continentId
   * @returns [latitude, longitude] if the coordinates are valid numbers.
   */
  overrideCords(continentId) {
    let coordinates;

    switch (continentId) {
      case "latam-caribbean":
        if (!isNaN(this.latamLatitude) && !isNaN(this.latamLongitude)) {
          coordinates = [this.latamLatitude, this.latamLongitude];
        }
        break;
      case "europe":
        if (!isNaN(this.europeLatitude) && !isNaN(this.europeLongitude)) {
          coordinates = [this.europeLatitude, this.europeLongitude];
        }
        break;
      case "asia":
        if (!isNaN(this.asiaLatitude) && !isNaN(this.asiaLongitude)) {
          coordinates = [this.asiaLatitude, this.asiaLongitude];
        }
        break;
      case "africa-middle-east":
        if (!isNaN(this.africaLatitude) && !isNaN(this.africaLongitude)) {
          coordinates = [this.africaLatitude, this.africaLongitude];
        }
        break;
      default:
        coordinates = null;
        break;
    }
    return coordinates;
  }
}
