/* eslint-disable camelcase */
import List from "list.js";

(() => {
  const BWDDestComparator = () => {
    const selectors = {
      component: "bwd-dest-comparator",
      packageComparatorComponent: "bwd-package-comparator",
      list: null,
    };

    const classes = {
      filterButton: ".js-open-filter",
      filterList: ".js-facets-list",
      filterBlock: ".js-filter-block",
      closeButton: ".js-close-menu",
      resetFilters: ".delete-filters-btn",
      hotelCard: ".result__list-item",
      numberOfHotels: ".hotels-size",
      packageCardHotels: ".bwd-package-detail-card__main__hotel",
      seeResultsButton: ".js-see-results",
      loadResults: ".js-load-results",
      showResults: ".js-filter-results",
    };

    const pushEvent = (category, action, label, value, event) => {
      try {
        const obj = {
          event_category: category,
          event_action: action && action.replace("_", " "),
          event_label: label && label.replace("_", " "),
          event: event && event.replace("_", " "),
        };

        if (value) {
          obj.event_value = value;
        }

        bcl.u.utag.push(obj);
      } catch (e) {
        console.error("Event push error", e);
      }
    };

    const filterHotels = (packageCards, hotelList) => {
      packageCards.forEach((packageCard) => {
        const hotels = packageCard.querySelectorAll(classes.packageCardHotels);
        const numberHotelsElem = packageCard.querySelector(classes.numberOfHotels);
        let visibleCount = 0;
        let totalCount = 0;

        hotels.forEach((hotel) => {
          const isHotelIncluded = hotelList.has(hotel.dataset?.hotelId);
          if (isHotelIncluded) {
            totalCount++;
          }
          const shouldShow = isHotelIncluded && visibleCount < 3;
          hotel.classList.toggle("hidden", !shouldShow);
          if (shouldShow) {
            visibleCount++;
          }
        });
        numberHotelsElem.innerText = totalCount;
      });
    };

    const loadData = (request) => {
      const data = JSON.parse(request.response);

      if (!data) {
        return;
      }

      const list = selectors.list;

      const results = data.results;
      const matchingIds = new Set();

      const component = document.querySelector(".bwd-dest-comparator");

      if (component.classList.contains(selectors.packageComparatorComponent)) {
        const hotelList = new Set();
        results.forEach((elem) => {
          matchingIds.add(`${elem.packageName}`);
          const hotelCompactList = elem.hotelReducedList;
          hotelCompactList.forEach((hotelCompact) => {
            hotelList.add(hotelCompact.hotelId);
          });
        });
        list.filter((item) => matchingIds.has(item.elm.getAttribute("data-card-id")));
        list.update();
        const packageCards = document.querySelectorAll(classes.hotelCard);
        filterHotels(packageCards, hotelList);
      } else {
        results.forEach((elem) => {
          matchingIds.add(elem.path || `${elem.hotelPage}/${elem.packageName}`);
        });
        list.filter((item) => matchingIds.has(item.elm.getAttribute("data-card-id")));
        list.update();
      }

      const resultButton = document.querySelector(classes.loadResults);
      const showResultButton = document.querySelector(classes.showResults);

      resultButton.innerHTML = data.total;
      showResultButton.innerHTML = data.total;
      const facets = data.facets;

      const allFilterInputs = component.querySelectorAll(".facet");
      allFilterInputs.forEach((input) => input.classList.add("hidden"));

      for (const facet of facets) {
        if (facet.items) {
          facet.items.forEach((item) => {
            const filterInput = component.querySelector(`div[data-dimension="${facet.field}"] .facet[data-facet="${item.selector}"]`);

            if (filterInput) {
              filterInput.classList.remove("hidden");
              const count = filterInput.querySelector(".count");
              count.innerHTML = item.count;
            }
          });
        }
      }

      bcl.u.removeBhgLoading();
    };

    const request = (reset, data, url) => {
      bcl.u.addBhgLoading();

      bcl.ajax.postRequest({
        data: data,
        url: url,
        callback: loadData,
        itemCallback: reset,
      });
    };

    const clickInFacet = ($checkbox, data, element) => {
      const dataset = element.querySelector(".c-hotels-comparator")?.dataset;
      const url = dataset?.url;
      const isOffer = dataset?.isOffer || "false";
      const requestData = { offer: [isOffer] };

      const selectedDimensions = [];
      const selectedFacets = [];

      for (const [dimension, facet] of Object.entries(data)) {
        selectedDimensions.push(dimension);
        selectedFacets.push(facet);

        if (!requestData[dimension]) {
          requestData[dimension] = [];
        }

        if (!requestData[dimension].includes(facet)) {
          requestData[dimension].push(facet);
        }
      }

      const finalRequestData = Object.keys(requestData).reduce((acc, dimension) => {
        acc[dimension] = requestData[dimension].join(",");
        return acc;
      }, {});

      const facetValue = selectedFacets.length > 0 ? selectedFacets.join(",") : "";
      const dimensionValue = selectedDimensions.length > 0 ? selectedDimensions.join(",") : "";

      if ($checkbox.checked && !$checkbox.disabled) {
        if (bcl.u.utag.isEnabled()) {
          pushEvent("filters|" + window.utag_data.pagecategorization, dimensionValue, facetValue, null, dimensionValue);
        }

        request("", finalRequestData, url);
      } else {
        const filterBlock = element.querySelector(classes.filterBlock);
        const filterInputs = filterBlock.querySelectorAll("input");
        if (filterInputs) {
          const allUnchecked = Array.from(filterInputs).every((input) => !input.checked);

          if (allUnchecked) {
            request("", requestData, url);
          } else {
            request("", finalRequestData, url);
          }
        }
      }
    };

    const paginationHandler = (element) => {
      const hotelList = element.getElementsByClassName("hotel-list")[0];
      const items = hotelList.querySelectorAll(classes.hotelCard);

      if (hotelList && items.length > 0) {
        if (!selectors.list) {
          // ListJS needs the target element to have and ID, generate a random one for each comparator in the page.
          hotelList.id = crypto.randomUUID().replace(/-/g, "");
          selectors.list = new List(hotelList, {
            page: 999,
            pagination: {
              innerWindow: 12,
              item: `<li><a class="page" href="#${element.id}"></a></li>`,
            },
          });
        }

        const paginationList = element.querySelector(".js-pagination-list .pagination");
        const paginationButton = element.querySelectorAll(".js-comparator-pagination-button");

        paginationButton.forEach((button) => {
          button.addEventListener("click", () => {
            let activeIndex = element.querySelector(".active a").dataset.i;
            if (button.classList.contains("prev")) {
              activeIndex--;
            } else if (button.classList.contains("next")) {
              activeIndex++;
            }

            if (activeIndex > 0 && activeIndex <= paginationList.children.length) {
              paginationList.querySelector(".active").classList.remove("active");
              paginationList.querySelector(`[data-i='${activeIndex}']`).click();
            }
          });
        });
      }
    };

    const init = () => {
      const parentElement = Array.from(document.getElementsByClassName(selectors.component));
      if (parentElement) {
        const body = document.querySelector("body");
        const isParentBwdPackage = parentElement.every((element) => element.classList.contains(selectors.packageComparatorComponent));
        if (parentElement.length > 0 && isParentBwdPackage) {
          setTimeout(function () {
            if (body.dataset.currency !== "USD") {
              const rate = bcl.s.currency.props.marketRelativeRatesMap.USD.rate;
              const inverseRate = bcl.s.currency.props.marketRelativeRatesMap.USD.inverse;
              bcl.s.currency.changeCurrency("USD", rate, null, true);
              const spans = body.querySelectorAll(".c-price__value-JS");
              spans.forEach((span) => {
                const price = span.dataset?.marketPrice;
                span.dataset.marketPrice = Math.ceil(price * inverseRate);
                span.dataset.relativeprice = price;
                span.innerText = bcl.s.currency.formatPrice(price);
              });
            }
          }, 500);
        }
        parentElement.forEach((element) => {
          element.id = crypto.randomUUID().replace(/-/g, "");
          const buttonFilter = element.querySelector(classes.filterButton);
          const filterBlock = element.querySelector(classes.filterBlock);
          const closeButton = element.querySelector(classes.closeButton);
          const resetButton = element.querySelector(classes.resetFilters);
          const filterInputs = filterBlock.querySelectorAll("input");
          const seeResultsButton = element.querySelector(classes.seeResultsButton);
          const url = element.querySelector(".c-hotels-comparator").getAttribute("data-url");
          const numerOfItems = element.querySelectorAll(classes.hotelCard).length;

          const resultButton = element.querySelector(classes.loadResults);
          const showResults = element.querySelector(classes.showResults);

          resultButton.innerHTML = parseInt(numerOfItems);
          showResults.innerHTML = parseInt(numerOfItems);

          if (buttonFilter) {
            buttonFilter.addEventListener("click", () => {
              if (filterBlock) {
                filterBlock.classList.add("open");
                document.querySelector(".c-header").style.zIndex = "-1";
              }
            });
          }

          if (closeButton) {
            closeButton.addEventListener("click", () => {
              if (filterBlock) {
                filterBlock.classList.remove("open");
                document.querySelector(".c-header").style.zIndex = "6";
              }
            });
          }

          if (seeResultsButton) {
            seeResultsButton.addEventListener("click", () => {
              if (filterBlock) {
                filterBlock.classList.remove("open");
                document.querySelector(".c-header").style.zIndex = "6";
              }
            });
          }

          if (resetButton) {
            resetButton.addEventListener("click", () => {
              if (filterInputs) {
                filterInputs.forEach((element) => {
                  element.checked = false;
                });
                request("", "", url);
              }
            });
          }
          if (filterInputs) {
            filterInputs.forEach((checkbox) => {
              checkbox.addEventListener("change", (evt) => {
                const clickedCheckboxDimension = evt.target.closest("div[data-dimension]").getAttribute("data-dimension");
                const isUniqueDimension = clickedCheckboxDimension === "hotel_destinations";
                const dimensionFacetMap = {};

                filterInputs.forEach((check) => {
                  if (check.checked) {
                    const dimension = check.closest("div[data-dimension]").getAttribute("data-dimension");
                    const facet = check.parentElement.parentElement.getAttribute("data-facet");
                    if (isUniqueDimension && dimension === clickedCheckboxDimension && check !== evt.target) {
                      check.checked = false;
                      return;
                    }

                    if (!dimensionFacetMap[dimension]) {
                      dimensionFacetMap[dimension] = [];
                    }
                    if (!dimensionFacetMap[dimension].includes(facet)) {
                      dimensionFacetMap[dimension].push(facet);
                    }
                  }
                });

                const requestData = Object.keys(dimensionFacetMap).reduce((acc, dimension) => {
                  acc[dimension] = dimensionFacetMap[dimension].join(",");
                  return acc;
                }, {});

                clickInFacet(checkbox, requestData, element);

                paginationHandler(element);
              });
            });
          }
          paginationHandler(element);
        });
      }
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDDestComparator);
})();
