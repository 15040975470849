(() => {
  const BWDSwiper = () => {
    const classes = {
      swiperComponent: ".js-bwd-swiper",
    };

    const init = () => {
      const weddingSwipers = Array.from(document.querySelectorAll(classes.swiperComponent));

      if (weddingSwipers.length > 0) {
        weddingSwipers.forEach((element) => {
          new window.Swiper(element, {
            slidesPerView: "auto",
            spaceBetween: 2,
            freeMode: true,
            mode: "horizontal",
            lazy: true,
            lazyPreloadPrevNext: 2,
            speed: 1100,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
        });
      }
    };

    init();
  };

  document.addEventListener("DOMContentLoaded", BWDSwiper);
})();
