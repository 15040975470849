(() => {
  const BWDArtCapt = () => {
    const selectors = {
      component: ".bwd-art-capt",
      video: ".wrap-art-video",
    };

    const classes = {
      play: "play",
    };
    const clicVideo = (e) => {
      const container = e.currentTarget.el;
      if (container) {
        container.classList.add(classes.play);
        container.removeEventListener("click", clicVideo);
        container.querySelector("video")?.play();
      }
    };
    const init = () => {
      const artCmpnts = Array.from(document.querySelectorAll(selectors.component));
      if (!artCmpnts) {
        return;
      }
      artCmpnts.forEach((el) => {
        const videos = el.querySelectorAll(selectors.video);
        if (videos) {
          videos.forEach((video) => {
            video.addEventListener("click", clicVideo);
            video.el = video;
          });
        }
      });
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDArtCapt);
})();
