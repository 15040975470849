/**
 * Handler for the bwd-map modal.
 */
export class Modal {
  /**
   * Initializes a Modal object.
   * @param {HTMLElement} elemContainer Container element of the component.
   * @param {() => void} closeFunc Function to call when close button is pressed.
   */
  constructor(elemContainer, closeFunc) {
    /** @type {HTMLElement} */
    this.elemModal = elemContainer.getElementsByClassName("bwd-map__modal")[0];

    /** @type {HTMLElement} */
    this.elemContent = this.elemModal.getElementsByClassName("bwd-map__modal__info")[0];

    this.elemModal.getElementsByClassName("bwd-map__modal__close")[0].addEventListener("click", closeFunc);
  }

  /**
   * Shows or hides the modal with the content provided.
   * @param {string|null|undefined} html HTML string to show on the modal, or falsy value to hide it.
   */
  showModal(html) {
    this.elemModal.classList.toggle("hidden", !html);
    if (html) {
      this.elemContent.innerHTML = html;
      new window.Swiper(this.elemContent.getElementsByClassName("swiper")[0], {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
  }
}
