import { iconPoint } from "./icons";
import { isTouchDevice } from "./input-device";
import { labelWithStyles } from "./styles";

let loaded = null;

/**
 * Loads the Google Maps API.
 * @returns {Promise<void>} Promise that resolves after the Google Maps API script has loaded.
 */
export const loadGMaps = () => {
  if (!loaded) {
    loaded = new Promise((resolve) => {
      window.bwdMapLoadCallback = () => {
        resolve();
        delete window.bwdMapLoadCallback;
      };

      bcl.u.loadScript({
        async: true,
        src: `https://maps.googleapis.com/maps/api/js?key=${window.googleApiKey}&loading=async&callback=bwdMapLoadCallback`,
        type: "text/javascript",
      });
    });
  }
  return loaded;
};

/**
 * Creates a new marker with the name and coords provided.
 * @param {string} name Name to display with the marker.
 * @param {Coordinates} coords Coordinates to display the marker at.
 * @returns {google.maps.Marker} New marker.
 */
export const newMarker = (name, coords) => {
  const marker = new window.google.maps.Marker({
    icon: iconPoint(),
    label: isTouchDevice() ? labelWithStyles(name) : null,
    position: new window.google.maps.LatLng(coords[0], coords[1]),
    title: name,
  });
  marker.addListener("mouseover", () => marker.setLabel(name));
  marker.addListener("mouseout", () => {
    if (!isTouchDevice()) {
      marker.setLabel(null);
    }
  });
  return marker;
};
