import * as bwdContactForm from "../bwd-contact-form/bwd-contact-form";

(() => {
  const BWDHotelDetail = () => {
    const selectors = {
      component: "bwd-hotel-detail",
    };

    const classes = {
      swiper: ".js-init-swiper",
      modalButton: ".js-open-modal",
      modalBlock: ".js-contact-modal",
    };

    const init = () => {
      Array.from(document.getElementsByClassName(selectors.component)).forEach((element) => {
        const swiperElement = element?.querySelector(classes.swiper);
        if (swiperElement) {
          new window.Swiper(swiperElement, {
            slidesPerView: 1,
            freeMode: false,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            loop: false,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        }

        const openModalButton = element?.querySelector(classes.modalButton);
        let alreadyAttachedToModal = false;
        if (openModalButton) {
          bwdContactForm.addEventListenerOnInserted((contactFormElem) => {
            if (alreadyAttachedToModal) {
              return;
            }
            alreadyAttachedToModal = true;

            const modal = contactFormElem.querySelector(classes.modalBlock);
            openModalButton.classList.remove("hidden");
            openModalButton.addEventListener("click", () => {
              if (modal) {
                modal.classList.add("mod--open");
                document.documentElement.classList.add("mod--disable-scroll");
              }
            });
          });
        }
      });
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDHotelDetail);
})();
