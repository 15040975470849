(() => {
  const BWDTripadvisorReviews = () => {
    const classes = {
      modifiers: {
        open: "mod--open",
        center: "mod--center",
        filled: "mod--filled",
        half: "mod--half",
        disableScroll: "mod--disable-scroll",
        hideEllipsis: "mod--hide-ellipsis",
      },
      component: ".bwd-tripadvisor-reviews",
      fullReview: ".js-bwd-tripadvisor-reviews-fullReview",
      wrapper: ".js-bwd-tripadvisor-reviews",
      swiperWrapper: ".js-bwd-tripadvisor-swiperWrapper",
      swiperSlide: ".js-bwd-tripadvisor-slide",
      closeBtn: ".js-bwd-tripadvisor-close",
      slide: {
        userName: ".js-bwd-tripadvisor-user-name",
        reviewDate: ".js-bwd-tripadvisor-user-date",
        reviewTitle: ".js-bwd-tripadvisor-info-title",
        reviewText: ".js-bwd-tripadvisor-info-description",
        reviewRating: ".js-bwd-tripadvisor-ratesblock-dots",
        reviewScore: ".js-bwd-tripadvisor-ratesblock-score",
        reviewTrip: ".js-bwd-tripadvisor-user-trip",
      },
      modal: {
        wrapper: ".js-bwd-tripadvisor-modal",
        userName: ".js-bwd-tripadvisor-modal-userName",
        userDate: ".js-bwd-tripadvisor-modal-userDate",
        userRating: ".js-bwd-tripadvisor-ratesblock-dots",
        userReviewTitle: ".js-bwd-tripadvisor-modal-infoTitle",
        userReview: ".js-bwd-tripadvisor-modal-infoDescription",
        userScore: ".js-bwd-tripadvisor-modal-ratesblockScore",
        userTrip: ".js-bwd-tripadvisor-modal-trip",
      },
    };

    const getReviews = (tripadvisorReviewsWrapper, component) => {
      const urlTripadvisor = tripadvisorReviewsWrapper.dataset.href;
      const minimunRating = tripadvisorReviewsWrapper.dataset.rating != null ? tripadvisorReviewsWrapper.dataset.rating : "0";
      if (urlTripadvisor != null) {
        let headersData = new Headers();
        headersData = headersData.set("Authorization", "Basic " + "admin" + ":" + "uaCe9oos4a");
        const fetchOptions = {
          headers: headersData,
          method: "GET",
        };
        fetch(urlTripadvisor, fetchOptions)
          .then(function (response) {
            if (!response.ok) {
              throw new Error(response.status);
            } else {
              return response.json();
            }
          })
          .then(function (data) {
            const slideBase = tripadvisorReviewsWrapper.querySelector(classes.swiperSlide);
            const slideWrapper = tripadvisorReviewsWrapper.querySelector(classes.swiperWrapper);

            data.reviews.forEach((review) => {
              if (minimunRating > review.rating) {
                return;
              }

              const slide = slideBase.cloneNode("deep");
              const reviewDate = new Date(review.published_date);
              const dateOptions = {
                year: "numeric",
                month: "short",
                day: "numeric",
              };

              const reviewScore = slide.querySelector(classes.slide.reviewScore);
              const reviewRating = slide.querySelector(classes.slide.reviewRating);

              slide.querySelector(classes.slide.userName).textContent = review.user.username;
              slide.querySelector(classes.slide.reviewTitle).textContent = review.title;
              slide.querySelector(classes.slide.reviewText).textContent = review.text;

              if (review.trip_type != null) {
                slide.querySelector(classes.slide.reviewDate).innerHTML = reviewDate.toLocaleDateString(document.documentElement.lang, dateOptions) + "<br>" + review.trip_type;
              } else {
                slide.querySelector(classes.slide.reviewDate).innerHTML = reviewDate.toLocaleDateString(document.documentElement.lang, dateOptions);
              }

              if (reviewScore != null && reviewRating != null) {
                reviewRating.innerHTML = "";
                if (reviewScore.dataset.round != undefined) {
                  reviewScore.textContent = Math.round(review.rating);
                } else {
                  reviewScore.textContent = review.rating;
                }

                const elementImageRating = document.createElement("img");
                elementImageRating.src = review.rating_image_url;
                reviewRating.append(elementImageRating);
              }

              slideWrapper.appendChild(slide);
            });
            slideBase.remove();

            const fullReviewBtn = slideWrapper.querySelectorAll(classes.fullReview);
            const modal = component.querySelector(classes.modal.wrapper);
            const closeModal = component.querySelector(classes.closeBtn);
            const html = document.querySelector("html");

            if (fullReviewBtn) {
              if (modal && closeModal) {
                fullReviewBtn.forEach((btn) => {
                  btn.addEventListener("click", () => {
                    const slideOrigin = btn.closest(classes.swiperSlide);
                    const reviewScore = modal.querySelector(classes.modal.userScore);
                    const reviewRating = modal.querySelector(classes.modal.userRating);

                    modal.querySelector(classes.modal.userName).textContent = slideOrigin.querySelector(classes.slide.userName).textContent;
                    modal.querySelector(classes.modal.userReviewTitle).textContent = slideOrigin.querySelector(classes.slide.reviewTitle).textContent;
                    modal.querySelector(classes.modal.userReview).textContent = slideOrigin.querySelector(classes.slide.reviewText).textContent;
                    modal.querySelector(classes.modal.userDate).innerHTML = slideOrigin.querySelector(classes.slide.reviewDate).innerHTML;
                    //modal.querySelector(classes.modal.userTrip).textContent = slideOrigin.querySelector(classes.slide.reviewTrip).textContent;

                    if (reviewScore != null && reviewRating != null) {
                      reviewRating.innerHTML = slideOrigin.querySelector(classes.slide.reviewRating).innerHTML;
                      reviewScore.textContent = slideOrigin.querySelector(classes.slide.reviewScore).textContent;
                    }

                    modal.classList.add(classes.modifiers.open);
                    html.classList.add(classes.modifiers.disableScroll);
                  });
                });

                closeModal.addEventListener("click", () => {
                  modal.classList.remove(classes.modifiers.open);
                  html.classList.remove(classes.modifiers.disableScroll);
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const centerSwiper = (swiperWrapper, swiperSlides) => {
      if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
        if (swiperSlides.length <= 3) {
          swiperWrapper.classList.add(classes.modifiers.center);
        } else {
          swiperWrapper.classList.remove(classes.modifiers.center);
        }
      } else if (window.innerWidth >= 1440 && window.innerWidth < 1800) {
        if (swiperSlides.length <= 4) {
          swiperWrapper.classList.add(classes.modifiers.center);
        } else {
          swiperWrapper.classList.remove(classes.modifiers.center);
        }
      } else {
        if (swiperSlides.length <= 5) {
          swiperWrapper.classList.add(classes.modifiers.center);
        } else {
          swiperWrapper.classList.remove(classes.modifiers.center);
        }
      }
    };

    const init = () => {
      const tripadvisorReviews = Array.from(document.querySelectorAll(classes.component));
      if (tripadvisorReviews.length > 0) {
        tripadvisorReviews.forEach((element) => {
          const tripadvisorReviewsWrapper = element.querySelector(classes.wrapper);

          if (!tripadvisorReviewsWrapper.hasAttribute("mode")) {
            getReviews(tripadvisorReviewsWrapper, element);

            if (tripadvisorReviewsWrapper) {
              new window.Swiper(tripadvisorReviewsWrapper, {
                slidesPerView: "auto",
                spaceBetween: 16,
                freeMode: false,
                loop: false,
              });

              const swiperWrapper = tripadvisorReviewsWrapper.querySelector(classes.swiperWrapper);
              if (swiperWrapper) {
                const swiperSlides = Array.from(swiperWrapper.querySelectorAll(classes.swiperSlide));

                centerSwiper(swiperWrapper, swiperSlides);

                window.addEventListener("resize", () => {
                  centerSwiper(swiperWrapper, swiperSlides);
                });
              }
            }
          }
        });
      }
    };

    init();
  };

  document.addEventListener("DOMContentLoaded", BWDTripadvisorReviews);
})();
